import React from "react"
import { Container, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logo from "../../images/logoblanco-02.png"

import "../../styles/home.css"

function Home() {
  return (
    <Container
      fluid
      className="bghome paddingTop_home  d-flex flex-column align-items-center"
    >
      <Col sm={7}>
        <img src={logo} className="mt-5 logohome" alt="logo" />
        <h2 className=" text-center text-white mb-5 font-weight-bold">
          {" "}
          Autodeterminación, inclusión y aprendizaje
        </h2>
        <div className=" d-flex flex-column align-items-center mt-5">
          <p className="text-white">Acompáñanos</p>
          <a className="text-light" href="#start">
            <FontAwesomeIcon
              className="  iconarrow "
              icon={["fas", "arrow-alt-circle-down"]}
            />
          </a>
        </div>
      </Col>
    </Container>
  )
}

export default Home
