import React from "react"
import { Container, Row, Col } from "reactstrap"
import "../../styles/layout.css"

//import video from '../../video/video.mp4';

function Video() {
  return (
    <Container fluid className="">
      <Row className="d-flex  justify-content-center my-5">
        <h1 className="text-center title_somos font-weight-bold">
          Generando oportunidades de vida
        </h1>
      </Row>
      <Row className=" paddingVideo d-flex justify-content-center">
        <Col className="indexContent_video " sm={12} md={10}>
          <video width="100%" controls>
            <source
              src="https://res.cloudinary.com/n12/video/upload/v1588173419/Villas%20Juan%20Pablo/villasjp_sitevideo_k783nq.mp4"
              type="video/mp4"
            />
          </video>
        </Col>
      </Row>
    </Container>
  )
}

export default Video
