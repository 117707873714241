import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import logo from "../../images/logoblanco-02.png"
import "../../styles/navb.css"

const routeLinks = [
  {
    to: "/#Dona",
    name: "Donaciones",
  },
  {
    to: "/us/",
    name: "Nosotros",
  },
  {
    to: "/prensa/",
    name: "Prensa",
  },
  {
    to: "/#Contact",
    name: "Contacto",
  },
]

function Navb() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Navbar className="nav_bar" fixed="top" dark expand="md">
      <NavbarBrand href="/">
        <img src={logo} className="img-fluid logo" alt="logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto " navbar>
          {routeLinks.map(({ to, name }, i) => (
            <NavItem className="m-0 " key={i}>
              <NavLink className="text-white " tag={Link} to={to}>
                {name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Navb
