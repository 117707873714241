import React, { useState } from "react"
//import { Link } from "gatsby"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import logo from "../../images/logoblanco-02.png"

import "../../styles/footer.css"

const routeLinks = [
  {
    to: "/#Dona",
    name: "Donaciones",
  },
  {
    to: "/us/",
    name: "Nosotros",
  },
  {
    to: "/#Contact",
    name: "Contacto",
  },
]

export default function Footer() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Container className="mt-5 bg_footer">
      <Row className="d-flex">
        <Col sm={4} className="">
          <img src={logo} alt="" className="img_lg" />
          <div className="ml-3 mt-1 txt_white_footer">
            En Villas Juan Pablo ayudamos a la educación especial para personas
            con Síndrome de Down. Autodeterminación, inclusión y aprendizaje.
          </div>
        </Col>

        <Col sm={4} className="text-center">
          <p className="mt-5 txt_white_footer font-weight-bold">
            Mapa del sitio
          </p>
          <div className="d-flex justify-content-center">
            <Nav className="mt-2">
              <NavItem className="text-center">
                <NavLink href="#Nosotros" className="txt_white_slim">
                  Quiénes Somos
                </NavLink>
                <NavLink href="#Donaciones" className="txt_white_slim">
                  Donaciones
                </NavLink>
                <NavLink href="#Pedidos" className="txt_white_slim">
                  Hacer pedido
                </NavLink>
                <NavLink href="#Contacto" className="txt_white_slim">
                  Contáctanos
                </NavLink>
                <NavLink href="/#prensa" className="txt_white_slim">
                  Prensa
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>

        <Col sm={4} className="mt-5 text-center ">
          <div className="txt_white_footer font-weight-bold">Síguenos</div>
          <div className="mt-3">
            <a
              className="mr-3 "
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/VillasJuanPabloac/"
            >
              <FontAwesomeIcon
                size="2x"
                className="text-light"
                icon={["fab", "facebook-f"]}
              />
            </a>
            <a
              className="mr-3 "
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/villas_juan_pablo_ac"
            >
              <FontAwesomeIcon
                size="2x"
                className="text-light"
                icon={["fab", "instagram"]}
              />
            </a>
            <a
              className="mr-3 "
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/villasjuanpablo"
            >
              <FontAwesomeIcon
                size="2x"
                className="text-light"
                icon={["fab", "twitter"]}
              />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center text-center">
        <Col>
          <Nav vertical>
            <NavItem>
              <NavLink
                className="text-light"
                target="_blank"
                rel="noopener noreferrer"
                href="https://n12.mx"
              >
                Hecho artesanalmente
                <span role="img" aria-label="palms facing together icon">
                  {" "}
                  🤲🏽
                </span>{" "}
                por N12 Estudio.
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </Container>
  )
}
