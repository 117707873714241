import React, { Component } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import left1 from "../../images/item_left.png"
import rigth1 from "../../images/graphics/texture-left.png"

import prensa1 from "../../pdfs/Institucionalidadytransparencia(2020).pdf"
import prensa2 from "../../pdfs/MeritoSocial.pdf"
import prensa3 from "../../pdfs/CorresposabilidadSocial.pdf"
import prensa4 from "../../pdfs/InstitucionalidadyTransparencia(2013).pdf"
import prensa5 from "../../pdfs/DesarrolloSocial.pdf"
import prensa6 from "../../pdfs/InformeAnual(2018).pdf"
import prensa7 from "../../pdfs/InformeAnual2019.pdf"
import prensa8 from "../../pdfs/InformeAnual2020.pdf"

import "../../styles/prensa.css"
const type = [
  {
    title: "Institucionalidad y Transparencia(2020)",
    src: prensa1,
  },
  {
    title: "Mérito Social",
    src: prensa2,
  },
  {
    title: "Corresponsabilidad Social",
    src: prensa3,
  },
  {
    title: "Institucionalidad y Transparencia(2013)",
    src: prensa4,
  },
  {
    title: "Desarrollo Social",
    src: prensa5,
  },
  {
    title: "Informe Anual (2018)",
    src: prensa6,
  },
  {
    title: "Informe Anual (2019)",
    src: prensa7,
  },
  {
    title: "Informe Anual (2020)",
    src: prensa8,
  },
]

export default class PrensaP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Institucionalidad y Transparencia(2020)",
      src:
        "https://drive.google.com/file/d/1ARLzg7Je4ZoXnFCboNT897p53m6bFYIf/preview",
    }
  }
  setType = (title, src) => {
    this.setState({
      selType: title,
      src,
    })
  }
  render() {
    const { selType, src: srcG } = this.state
    return (
      <Container className="mt-5 pt-5">
        <img src={left1} className="itemR1PP img-fluid overH" alt="" />
        <img src={rigth1} className="itemL1PP img-fluid" alt="" />
        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            <h1 className="title_somos font-weight-bold my-3">Prensa</h1>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <p>
              Villas Juan Pablo ha recibido diferentes certificados y
              reconocimientos como muestra del trabajo constante, estable,
              eficaz y confiable, que muestra la responsabilidad que tiene con
              su comunidad, con la sociedad Mexicana y con todos sus
              colaboradores.
            </p>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={5} className="">
            <h5 className="font-weight-bold">
              Certificados y Reconocimientos:
            </h5>
            <Col className="">
              <Nav className="d-flex flex-column justify-content-start">
                {type.map(({ title, src }, i) => (
                  <NavItem
                    onClick={() => this.setType(title, src)}
                    onMouseEnter={() => this.setType(title, src)}
                    className="areas_trabajadas_hover"
                    key={i}
                  >
                    <NavLink
                      tag="span"
                      active={selType === title}
                      className="mt-3 link_nav_us"
                    >
                      {" "}
                      <FontAwesomeIcon icon={["fas", "circle"]} />
                      &nbsp;{title}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Col>

          <Col sm={12} md={7} className="">
            <TransitionGroup key={srcG} appear={true} exit={true}>
              <CSSTransition timeout={300} classNames="method_info">
                <div>
                  <embed
                    src={srcG}
                    type="application/pdf"
                    width="100%"
                    height="400"
                  />
                  <br />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Col>
        </Row>
      </Container>
    )
  }
}
