import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faEye,
  faArrowAltCircleDown,
  faStreetView,
  faBook,
  faDotCircle,
  faCircle,
  faEnvelope,
  faPhone,
  faHome,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons"

export default [
  fab,
  faEye,
  faArrowAltCircleDown,
  faStreetView,
  faBook,
  faDotCircle,
  faCircle,
  faEnvelope,
  faPhone,
  faHome,
  faSpinner,
]
