import React from "react"
import {
  Container,
  Row,
  Col,
  CardText,
  CardBody,
  CardTitle,
  Card,
  CardImg,
} from "reactstrap"
import "../../styles/somos.css"

import asesorias from "../../images/quienesSomos/asesorias.svg"
import inclusion from "../../images/quienesSomos/inclusion.svg"
import lucro from "../../images/quienesSomos/lucro.svg"
import orientacion from "../../images/quienesSomos/orientacion.svg"
import personal from "../../images/quienesSomos/personal.svg"
import talleres from "../../images/quienesSomos/talleres.svg"

import left from "../../images/item_left.png"

const contentCards = [
  {
    title: "Talleres",
    imgC: talleres,
    text:
      "Basados en la personalidad y motivación, con el fin de mejorar los procesos de aprendizaje y obtener un mejor desempeño académico y psicológico.",
  },
  {
    title: "Inclusión laboral",
    imgC: inclusion,
    text:
      "Trabajamos el desarrollo integral para que se incluyan en los diferentes ámbitos de la sociedad.",
  },
  {
    title: "Asesorías Externas",
    imgC: asesorias,
    text:
      "Asesoría a escuelas con inclusión educativa, asesoría a empresas con inclusión laboral.",
  },
  {
    title: "Sin fines de lucro",
    imgC: lucro,
    text:
      "Las cuotas de recuperación son de acuerdo al nivel socioeconómico y  éste no sea un límite para recibir educación de calidad.",
  },
  {
    title: "Profesionalismo",
    imgC: personal,
    text: "Contamos con personal capacitado y experimentado.",
  },
  {
    title: "Orientación y Apoyo Familiar",
    imgC: orientacion,
    text:
      "Ofrecemos a las familias apoyo emocional, económico y orientación para que formen parte activa de la educación de sus hijos.",
  },
]

function Somos() {
  return (
    <Container className="p-0">
      <div className="indexContentS">
        <img src={left} className="itemL img-fluid" alt="" />
        <Row className="d-flex justify-content-center mx-0  ">
          <h1 className="title_somos font-weight-bold my-5">Quiénes somos</h1>
          <p className="text-center paddSubtSomos mb-0">
            {" "}
            Somos una institución con una trayectoria desde 1987 y sin fines de
            lucro, comprometida a ofrecer educación y capacitación a personas
            con Síndrome de Down, desarrollando nuestras propias metodologías.
          </p>{" "}
          <br />
          <br />
        </Row>
        <Row className="flexcards mx-0  ">
          {contentCards.slice(0, 3).map(({ title, imgC, text }, i) => (
            <Col key={i} className=" p-0" sm={4}>
              <Card className="text-center align-items-center border-0">
                <CardImg
                  className="img-fluid  somos_card_img"
                  src={imgC}
                  alt="icon"
                />
                <CardBody>
                  <CardTitle className="mt-3 text-center font-weight-bold">
                    {title}
                  </CardTitle>
                  <CardText className=" text-center ">{text}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="flexcards mx-0  ">
          {contentCards.slice(3).map(({ title, imgC, text }, i) => (
            <Col key={i} className=" p-0" sm={4}>
              <Card className="text-center align-items-center border-0">
                <CardImg
                  className="img-fluid  somos_card_img"
                  src={imgC}
                  alt="icon"
                />
                <CardBody>
                  <CardTitle className="mt-3 text-center font-weight-bold">
                    {title}
                  </CardTitle>
                  <CardText className=" text-center ">{text}</CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  )
}

export default Somos
