import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "../../styles/educacion.css"

class Capacitacion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Autonomía personal y social",
      src: this.props.queryData.allFile.nodes[1].childImageSharp.fluid,
    }
  }
  setType = (title, src) => {
    this.setState({ selType: title, src })
  }
  render() {
    const ejes = [
      {
        title: "Cognitivo",
        src: this.props.queryData.allFile.nodes[1].childImageSharp.fluid,
      },
      {
        title: "Productivo",
        src: this.props.queryData.allFile.nodes[0].childImageSharp.fluid,
      },
      {
        title: "Vida independiente",
        src: this.props.queryData.allFile.nodes[2].childImageSharp.fluid,
      },
    ]
    const { selType, src: srcG } = this.state
    return (
      <Container className="mt-5">
        <div className="indexContentE">
          {/* <img src={square} className="square img-fluid" alt="" />
          <img src={textures} className="textures img-fluid" alt="" /> */}
          <Col className="">
            <h3 className=" title_somos font-weigth-bold">
              Centro de Capacitación para la Inclusión Laboral
            </h3>
            <p className="subtEdu">
              Desarrollamos habilidades que capaciten a jóvenes y adultos con
              Síndrome de Down y Discapacidad Intelectual para la Inclusión
              Laboral en un empleo formal y así logran una vida productiva,
              digna y autorrealizada, que les permita acceder a una mejor
              “Calidad de Vida”.
            </p>
          </Col>
          <Row className="areaMarging">
            <Col sm={12} md={6} className="d-flex justify-content-center mxH">
              <TransitionGroup key={srcG} appear={true} exit={true}>
                <CSSTransition timeout={400} classNames="method_info abs_cont ">
                  <div className="img_cont">
                    <Img
                      fluid={srcG}
                      alt="descripción de habilidades"
                      className="img_modifier_educacion"
                    />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </Col>
            <Col sm={12} md={6} className="">
              <h5 className="font-weight-bold">Trabajamos sobre tres ejes:</h5>
              <Col className="contAreas my-4 ">
                <Nav className="d-flex flex-column justify-content-start">
                  {ejes.map(({ title, src }, i) => (
                    <NavItem
                      className="areas_trabajadas_hover"
                      onClick={() => this.setType(title, src)}
                      onMouseEnter={() => this.setType(title, src)}
                      key={i}
                    >
                      <NavLink
                        className="pb-1 point subtEdu"
                        tag="span"
                        active={selType === title}
                      >
                        <FontAwesomeIcon icon="dot-circle" />
                        &nbsp;
                        {title}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
}

function CapacitacionWQry(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "capacitacion" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxHeight: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => <Capacitacion queryData={data} {...props} />}
    />
  )
}

export default CapacitacionWQry
