import React, { Component } from "react"
import { Capacitacion } from "./"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import autonomia from '../../images/educacion/autonomia.jpg'
// import academica from '../../images/educacion/academica.jpg'
// import gruesa from '../../images/educacion/gruesa.jpg'
// import fina from '../../images/educacion/fina.jpg'
// import lenguaje from '../../images/educacion/lenguaje.png'
// import senso from '../../images/educacion/senso.jpg'

import square from "../../images/square.png"
import textures from "../../images/textures.png"

import "../../styles/educacion.css"

class Educacion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Autonomía personal y social",
      src: this.props.queryData.allFile.nodes[4].childImageSharp.fluid,
    }
  }
  setType = (title, src) => {
    this.setState({ selType: title, src })
  }
  render() {
    const types = [
      {
        title: "Autonomía personal y social",
        src: this.props.queryData.allFile.nodes[4].childImageSharp.fluid,
      },
      {
        title: "Académica",
        src: this.props.queryData.allFile.nodes[0].childImageSharp.fluid,
      },
      {
        title: "Motora gruesa",
        src: this.props.queryData.allFile.nodes[2].childImageSharp.fluid,
      },
      {
        title: "Motora fina",
        src: this.props.queryData.allFile.nodes[1].childImageSharp.fluid,
      },
      {
        title: "Lenguaje",
        src: this.props.queryData.allFile.nodes[3].childImageSharp.fluid,
      },
      {
        title: "Sensopercepción",
        src: this.props.queryData.allFile.nodes[5].childImageSharp.fluid,
      },
    ]

    const { selType, src: srcG } = this.state
    return (
      <Container className="mt-5">
        <div className="indexContentE">
          <img src={square} className="square img-fluid" alt="" />
          <img src={textures} className="textures img-fluid" alt="" />
          <Row className="d-flex justify-content-start ">
            <Col className="">
              <h1 className="title_educacion font-weight-bold mt-5 ">
                Educación
              </h1>
              <p>
                En Villas Juan Pablo nos centramos en dos áreas principales:
              </p>
              <h3 className="font-weigth-bold title_somos">Escuela</h3>
              <p className="subtEdu">
                Nuestros programas están basados en las necesidades específicas
                de nuestros beneficiarios, potenciando sus fortalezas para
                lograr su independencia y se incluyan en la vida social, escolar
                y laboral. Los programas son individualizados y los grupos son
                pequeños.
              </p>
            </Col>
          </Row>
          <Row className="areaMarging">
            <Col sm={12} md={6} className="">
              <h5 className="font-weight-bold">Trabajamos en las áreas de:</h5>
              <Col className="contAreas my-4 ">
                <Nav className="d-flex flex-column justify-content-start">
                  {types.map(({ title, src }, i) => (
                    <NavItem
                      className="areas_trabajadas_hover"
                      onClick={() => this.setType(title, src)}
                      onMouseEnter={() => this.setType(title, src)}
                      key={i}
                    >
                      <NavLink
                        className="pb-1 point subtEdu"
                        tag="span"
                        active={selType === title}
                      >
                        <FontAwesomeIcon icon="dot-circle" />
                        &nbsp;
                        {title}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Col>
            <Col sm={12} md={6} className="d-flex justify-content-center mxH">
              <TransitionGroup key={srcG} appear={true} exit={true}>
                <CSSTransition timeout={400} classNames="method_info abs_cont ">
                  <div className="img_cont">
                    <Img
                      fluid={srcG}
                      alt="descripción de habilidades"
                      className="img_modifier_educacion"
                    />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </Col>
          </Row>
          <Capacitacion />
        </div>
      </Container>
    )
  }
}

function EducacionWQry(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativeDirectory: { eq: "educacion" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => <Educacion queryData={data} {...props} />}
    />
  )
}

export default EducacionWQry
