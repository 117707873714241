import React from "react"
import { Container, Col, Row } from "reactstrap"

import left1 from "../../images/item_left.png"
import rigth1 from "../../images/graphics/texture-left.png"

import "../../styles/us.css"

function UsP() {
  return (
    <Container className=" ">
      <div lassName="indexContentUsP ">
        <img src={left1} className="itemR1UsP img-fluid overH" alt="" />
        <img src={rigth1} className="itemL1UsP img-fluid" alt="" />
        <Row className=" paddingTop_Us justify-content-center my-5">
          <h1 className=" text-center mt-5 title_somos font-weight-bold">
            {" "}
            Sobre Villas Juan Pablo{" "}
          </h1>
        </Row>

        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            {" "}
            <h2 className="title_somos font-weight-bold">Misión</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p>
              "En Villas Juan Pablo A.C., reconocemos el derecho de la vida
              digna de la persona con Síndrome de Down y luchamos para que a
              través de la educación adquiera las herramientas para acceder a
              todos los derechos que le corresponden para mejorar su calidad de
              vida."
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            {" "}
            <h2 className="title_somos font-weight-bold">Visión</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p>
              "Ser una asociación consolidada y seria en la Ciudad de Puebla
              cuyo modelo educativo pueda ser replicado a nivel nacional."
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            {" "}
            <h2 className="title_somos font-weight-bold">Valores</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p className="fontSUsP">
              {" "}
              <b>Responsabilidad:</b> Tenemos un compromiso social con nuestros
              alumnos y sus familias para que a través de la educación de
              calidad, con personal calificado y a la vanguardia en materia
              educativa, logren mejorar su Calidad de Vida.{" "}
            </p>
            <p className="fontSUsP">
              <b>Honestidad:</b> Estamos comprometidos con la verdad y la
              transparencia y somos congruentes entre lo que pensamos y hacemos.{" "}
            </p>
            <p className="fontSUsP">
              <b>Empatía:</b> Entendemos el impacto de recibir en la familia a
              una persona con discapacidad, por lo que ofrecemos apoyo,
              orientación y colaboración a las familias de personas con Síndrome
              de Down respetando su entorno.
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            {" "}
            <h2 className="title_somos font-weight-bold">Metas</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {" "}
            <p className="fontSUsP">
              Educar a las personas con Síndrome de Down para que desarrollen al
              máximo ses habilidades y capacidades.
            </p>
            <p className="fontSUsP">
              Desarrollar en las personas con Síndrome de Down habilidades que
              los capaciten para la inclusión laboral en un empleo formal y así
              lograr una vida productiva, digna y autorealizada.{" "}
            </p>
            <p className="fontSUsP">
              Orientar y apoyar a las Familias de las personas con Síndrome de
              Down.
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-start">
          <Col sm={12} md={8} className="">
            {" "}
            <h2 className="title_somos font-weight-bold">Historia</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {" "}
            <p className="fontSUsP">
              Villas Juan Pablo es un Asociación Civil que desde hace 32 años de
              manera ininterrumpida se ha dedicado a la educación y capacitación
              de personas con Síndrome de Down de escasos recursos.{" "}
            </p>
            <p className="fontSUsP">
              Durante este tiempo de servicio ha cambiado la vida de más de 300
              familias al capacitar a todos los miembros a través de talleres de
              padres y hermanos, conferencias, sesiones familiares
              individualizadas y el trabajo directo con la persona con Síndrome
              de Down dando el apoyo necesario para que logre desarrollar sus
              capacidades motrices, intelectuales y adquieran conductas
              socio-adaptativas para desenvolverse correctamente ante la
              sociedad.
            </p>
            <p className="fontSUsP">
              En Villas Juan Pablo reconocemos que la dignidad es un valor
              inherente al Ser Humano por el simple hecho de serlo sin importar
              raza, sexo, condición social o cualquier condición física o
              intelectual ya que es consustancial al Ser Humano.
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default UsP
